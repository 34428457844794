/* Header */
header {
  height: 100vh;
  max-height: 400px;
  display: flex;
  flex-direction: column;
  background-position: center;
  background-size: cover;
  background-attachment: fixed;
  border-bottom: 5px solid #e67e22;
}

.loading {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.navbar {
  background: white;
  padding: 10px;
  font-size: 32px;
}

.information {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.info-brand {
  font-size: 62px;
  font-weight: bold;
}

.info-information {
  max-width: 500px;
}

.info-icon {
  height: 50px;
  position: relative;
}

.information-title{
  font-size: 42px;
  font-weight: bold;
}

/* Section */

section {
  margin-top: 20px;
  padding: 5px;
}

.section-content {
  margin: 0 auto;
  max-width: 960px;
}

.main-title {
  font-size: 32px;
  text-align: center;
}

/* Project */

.project-list {
  margin-top: 20px;
  display: grid;
  grid-template-columns: repeat(3, minmax(250px, 1fr) );
  grid-gap: 10px;
}

@media screen and (max-width: 960px) {
  .project-list {
    grid-template-columns: repeat(2, minmax(250px, 1fr) );
  }
}

@media screen and (max-width: 650px) {
  .project-list {
    grid-template-columns: repeat(1, minmax(300px, 1fr) );
  }
}

.project {
  overflow: hidden;
  cursor: pointer;
}

.project-empty {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 10px;
  min-height: 200px;
}

.project-info {}

.project-thumbnail {
  background: gray;
  height: 200px;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  position: relative;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: white;
  z-index: 99;
  animation-duration: 0.3s;

  display: flex;
  flex-direction: column;
}

.modal-content {
  flex: 1;
  overflow-y: auto;
  padding: 10px;
}

.photo-gallery-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px,1fr));
  grid-gap: 10px;
}

.photo-gallery-grid-item {
  height: 150px;
  width: 100%;
  background-position: center center;
  background-size: cover;
}

/* Scroll */

.icon-scroll,
.icon-scroll:before {
    position: absolute;
    left: 50%;
}

.icon-scroll {
  top: 100px;
    width: 30px;
    height: 50px;
    margin-left: -20px;
    bottom: 10px;
    margin-top: -35px;
    border: 2px solid #e67e22;
    border-radius: 25px;
}

@media (max-width: 767px) {
    .icon-scroll {
        position: relative
    }
}

.icon-scroll:before {
    content: '';
    width: 8px;
    height: 8px;
    background: #e67e22;
    margin-left: -4px;
    top: 8px;
    border-radius: 4px;
    animation-duration: 1.5s;
    animation-iteration-count: infinite;
    animation-name: scroll
}

@keyframes scroll {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
        transform: translateY(26px);
    }
}